import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ModalVideo from 'react-modal-video'
import abimg5 from '../..//images/resource/about.png'
import { Roll } from "react-awesome-reveal";


import useResponsiveLink from '../../Hooks/useResponsiveLink';

const About = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const [isOpen, setOpen] = useState(false)

    const deviceLink = useResponsiveLink()
    
    return (

        <section className="about-one" id="about">
            
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="about-one_image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="about-one_image-inner">
                            <Roll left triggerOnce={'false'}>
                                <div className="about-one_image">
                                    <img src={abimg5} alt="" />
                                </div>
                            </Roll>
                            <button onClick={() => setOpen(true)} className="lightbox-video slider-one_play"><span className="fa fa-play"><i className="ripple"></i></span></button>
                        </div>
                    </div>
                    <div className="about-one_content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="about-one_content-inner">
                            <div className="sec-title title-anim">
                                {/* <div className="sec-title_title">About Event</div> */}
                                <h2 className="sec-title_heading1">Diplomado de medicina funcional</h2>
                                <div>
                                    <p style={{ fontSize: '25px'}} className='sub-centered'>Basada en la evidencia</p>
                                </div>
                            </div>
                            <p className="about-text">Impulsa tu carrera médica con nuestro diplomado internacional de medicina funcional. Estudio flexible adaptado a ti: aprendizaje personalizado, acceso 24/7, sesiones virtuales en vivo y recursos multimedia.<br /> <br /> Avanza a tu ritmo con soporte experto continuo. Descubre más y eleva tu práctica profesional hoy.</p>
                            <div className="row clearfix">
                                <div className="about-one_block col-lg-6 col-md-6 col-sm-12">
                                    <div className="about-one_block-inner">
                                        <span className="about-one_block-icon flaticon-calendar-2"></span>
                                        <strong>Fecha</strong>
                                        <strong>21 de abril al 10 de noviembre</strong> <br />
                                        <span className='bolder'>AFMCP</span> - 21 de abril al 10 de julio <br />
                                        <span className='bolder'>Diplomado</span> - 10 de julio al 10 noviembre
                                    </div>
                                </div>
                                <div className="about-one_block col-lg-6 col-md-6 col-sm-12">

                                </div>
                            </div>
                            <div className="price-block_one-button">
                                <a href={deviceLink} target="_blank" rel="noreferrer" class="btn-about">Deseo saber más</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalVideo channel='vimeo' autoplay isOpen={isOpen} videoId="1046839038" onClose={() => setOpen(false)} />
        </section>
    )
}

export default About;