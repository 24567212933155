import Navbar from "../../components/Navbar/Navbar";
import Band from "../../components/Band/Band";
import About from "../../components/about/about";
import FunFact from "../../components/FunFact";
import Speakers from "../../components/Speakers/Speakers";
import GallerySection from "../../components/GallerySection/GallerySection";
import PricingNew from "../../components/PricingNew/PricingNew";
import PartnerSection from "../../components/PartnerSection";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Hubspot from "../../components/Hubspot/Hubspot";
import PromBanner from "../../components/PromBanner/PromBanner";
import Calendar from "../../components/Calendar/Calendar";
import Certifications from "../../components/Certifications/Certifications";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import avatarW from "../../images/resource/avatar-whatsapp.png";
import '../../css/WhatsAppStyles.css'
import Prueba from "../../components/Prueba/Prueba";


const HomePage = () => {
  return (
    <main className="content-home">
      <Navbar />
      <Prueba />
      <Band />
      <Certifications />
      <About />
      <FunFact />
      <Speakers />
      <GallerySection />
      <Calendar />
      <PricingNew />
      <Hubspot />
      <PartnerSection />
      <PromBanner />
      <FloatingWhatsApp
        phoneNumber="573153063849"
        accountName="AMF"
        avatar={avatarW}
        statusMessage="Te responderemos en unos minutos"
        chatMessage="¡Hola! 👋 ¿En qué puedo ayudarte?"
        placeholder="Escribe tu mensaje..."
        notificationSound = {true}
        allowClickAway
        allowEsc
      />
      <Footer />
      <Scrollbar />
    </main>
  );
};
export default HomePage;
