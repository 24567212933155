import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-scroll";
import { connect } from "react-redux";
import { removeFromCart } from "../../store/actions/action";
import logoLight from "../../images/logo.png";
import logoDark from "../../images/iconScrolled.png";
import MobileMenu from "../MobileMenu/MobileMenu";

const Header = (props) => {
  const [searchActive, setSearchState] = useState(false);
  const [cartActive, setCartState] = useState(false);
  const [color, setColor] = useState("white");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Detectar si es móvil al cargar
  const [logo, setLogo] = useState(window.innerWidth <= 768 ? logoDark : logoLight); // Inicializar el logo correctamente
  const divRef = useRef();

  useEffect(() => {
    const updateIsMobile = () => {
      const isMobileView = window.innerWidth <= 768;
      setIsMobile(isMobileView);
      setLogo(isMobileView ? logoDark : window.scrollY > 0 ? logoDark : logoLight);
    };

    // Detectar si es móvil al cambiar el tamaño de la ventana
    window.addEventListener("resize", updateIsMobile);

    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  useEffect(() => {
    const handlerScroll = () => {
      const scrollPosition = window.scrollY;
      setColor(scrollPosition > 0 ? "black" : "white");
      setLogo(isMobile ? logoDark : scrollPosition > 0 ? logoDark : logoLight);
    };

    window.addEventListener("scroll", handlerScroll);

    return () => {
      window.removeEventListener("scroll", handlerScroll);
    };
  }, [isMobile]);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <header className={`main-header ${isMobile ? "mobile-header" : ""}`}>
      <div className="header-upper" style={{ backgroundColor: isMobile ? "white" : "transparent" }}>
        <div className="auto-container">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            {/* Logo */}
            <div className="logo-box">
              <div className="logo">
                <Link onClick={ClickHandler} to="/home">
                  <img src={logo} alt="Logo" />
                </Link>
              </div>
            </div>
            <div className="nav-outer">
              {/* Main Menu */}
              <nav className="main-menu navbar-expand-md">
                <div className="navbar-header">
                  {/* Toggle Button */}
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span
                      ref={divRef}
                      style={{ color }}
                      className="icon-bar"
                    ></span>
                    <span
                      ref={divRef}
                      style={{ color }}
                      className="icon-bar"
                    ></span>
                    <span
                      ref={divRef}
                      style={{ color }}
                      className="icon-bar"
                    ></span>
                  </button>
                </div>

                <div
                  className="navbar-collapse collapse clearfix"
                  id="navbarSupportedContent"
                >
                  <ul className="navigation clearfix">
                    <li>
                      <Link
                        ref={divRef}
                        style={{ color }}
                        onClick={ClickHandler}
                        activeClass="active"
                        to="section1"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={1000}
                      >
                        Educadores
                      </Link>
                    </li>
                    <li>
                      <Link
                        ref={divRef}
                        style={{ color }}
                        onClick={ClickHandler}
                        activeClass="active"
                        to="section2"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={1000}
                      >
                        Etapas de pago
                      </Link>
                    </li>
                    <li>
                      <Link
                        ref={divRef}
                        style={{ color }}
                        onClick={ClickHandler}
                        activeClass="active"
                        to="section3"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={1000}
                      >
                        Certificaciones
                      </Link>
                    </li>
                    <li>
                      <Link
                        ref={divRef}
                        style={{ color }}
                        onClick={ClickHandler}
                        activeClass="active"
                        to="section4"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={1000}
                      >
                        Contacto
                      </Link>
                    </li>
                      
                      <a
                        href="https://edu.academiademedicinafuncional.info/producto/diplomado-medicina-funcional-2025-afmcp/"
                        target="_blank"
                        rel="noreferrer"
                        className="btn-header1"
                      >
                        Inscríbete
                      </a>
                   
                    
                  </ul>
                </div>
              </nav>
            </div>

            {/* Main Menu End*/}
            <div className="outer-box d-flex align-items-center flex-wrap">
              {/* Mobile Navigation Toggler */}
              <MobileMenu/>
            </div>
          </div>
        </div>
      </div>
      {/*End Header Upper*/}
      <div className={`${searchActive ? "search-active" : ""}`}>
        <div className="search-popup">
          <div className="color-layer"></div>
          <button
            className="close-search"
            onClick={() => setSearchState(!searchActive)}
          >
            <span className="flaticon-cancel-1"></span>
          </button>
          {/* <form method="post" onSubmit={SubmitHandler}>
            <div className="form-group">
              <input
                type="search"
                name="search-field"
                placeholder="Search Here"
                required=""
              />
              <button
                className="fa fa-solid fa-magnifying-glass fa-fw"
                type="submit"
              ></button>
            </div>
          </form> */}
        </div>
      </div>
      <div
        className={`xs-sidebar-group info-group ${
          cartActive ? "isActive" : ""
        }`}
      >
        <div
          className="xs-overlay xs-bg-black"
          onClick={() => setCartState(!cartActive)}
        ></div>
        <div className="xs-sidebar-widget">
          <div className="sidebar-widget-container">
            <div
              className="close-button"
              onClick={() => setCartState(!cartActive)}
            >
              <span className="flaticon-cancel-1"></span>
            </div>
            <div className="sidebar-textwidget">
              <div className="sidebar-info-contents">
                <div className="content-inner">
                  <div className="title-box">
                    <h5>
                      Shopping <span>Bag</span>
                    </h5>
                    <div className="price">$15 from free economy shipping</div>
                  </div>
                  <div className="empty-cart-box">
                    <div className="no-cart">
                      <span className="icon">
                        <i className="fi flaticon-shopping-cart"></i>
                      </span>
                      No products in cart.
                    </div>
                  </div>

                  <div className="lower-box">
                    <h5>
                      Popular <span>Suggestions</span>
                    </h5>
                    <div className="post-block">
                      <div className="inner-box">
                        <div className="image">
                          {/*  <img src={pr1} alt="" /> */}
                        </div>
                        <h6>
                          <Link to="/">Children Event</Link>
                        </h6>
                        <div className="rating">
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                        </div>
                        <div className="price-box">$125</div>
                        <Link className="theme-btn bag-btn" to="/">
                          add to bag
                        </Link>
                      </div>
                    </div>
                    <div className="post-block">
                      <div className="inner-box">
                        <div className="image">
                          {/*  <img src={pr2} alt="" /> */}
                        </div>
                        <h6>
                          <Link to="/">Marriage Caremoney</Link>
                        </h6>
                        <div className="rating">
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                        </div>
                        <div className="price-box">$205</div>
                        <Link className="theme-btn bag-btn" to="/">
                          add to bag
                        </Link>
                      </div>
                    </div>
                    <div className="post-block">
                      <div className="inner-box">
                        <div className="image">
                          {/* <img src={pr3} alt="" /> */}
                        </div>
                        <h6>
                          <Link to="/">baby shower</Link>
                        </h6>
                        <div className="rating">
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                        </div>
                        <div className="price-box">$125</div>
                        <Link className="theme-btn bag-btn" to="/">
                          add to bag
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
  };
};

export default connect(mapStateToProps, { removeFromCart })(Header);
